<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppFooter",
  i18n: {
    messages: {
      en: {
        support: "Support service:",
        footerText:
          "OptimaX Finance provides services in accordance with regulatory requirements and in accordance with the terms of the license. Registered in England and Wales at 1 St Martin's Le Grand, London EC1A 4EU, United Kingdom. Investing in financial instruments provides the opportunity to obtain high profits, but also involves certain risks. It is important to review each instrument carefully before you start investing. There is a possibility of losing your investment, so it is advisable to avoid risks that you are not prepared to take. It is highly recommended to study the features of each financial instrument before you start trading. The contents of this website do not constitute advice. Remember that past performance is no guarantee of future success. We recommend that you spread your investment portfolio to minimize risks.",
      },
      ru: {
        support: "Служба поддержки:",
        footerText:
          "OptimaX Finance предоставляет услуги в соответствии с нормативными требованиями и в соответствии с условиями лицензии. Зарегистрирована в Англии и Уэльсе по адресу 1 St Martin's Le Grand, London EC1A 4EU, United Kingdom.Инвестирование в финансовые инструменты, дает возможность получения высокой прибыли, но также  сопряжено с определенными рисками. Прежде чем начать инвестировать, важно внимательно изучить каждый инструмент. Существует вероятность потери вложенных средств, поэтому желательно избегать риска, к которому вы не готовы. Настоятельно рекомендуется изучить особенности каждого финансового инструмента, прежде чем приступить к торговле. Содержание этого веб-сайта не является рекомендацией. Помните, что прошлые результаты не являются гарантией будущего успеха. Рекомендуем вам распределить свой инвестиционный портфель, чтобы минимизировать риски.",
      },
      fr: {
        support: "Service d'assistance:",
        footerText:
          "OptimaX Finance fournit des services conformément aux exigences réglementaires et conformément aux termes de la licence. Enregistrée en Angleterre et au Pays de Galles au 1 St Martin's Le Grand, London EC1A 4EU, United Kingdom, Royaume-Uni Investir dans des instruments financiers offre la possibilité d'obtenir des bénéfices élevés, mais implique également certains risques. Il est important d’examiner attentivement chaque instrument avant de commencer à investir. Il existe une possibilité de perdre votre investissement, il est donc conseillé d’éviter les risques que vous n’êtes pas prêt à prendre. Il est fortement recommandé d'étudier les caractéristiques de chaque instrument financier avant de commencer à trader. Le contenu de ce site Web ne constitue pas un conseil. N'oubliez pas que les performances passées ne garantissent pas le succès futur. Nous vous recommandons de répartir votre portefeuille de placements afin de minimiser les risques.",
      },
      de: {
        support: "Support-Service:",
        footerText:
          "OptimaX Finance erbringt Dienstleistungen im Einklang mit den gesetzlichen Anforderungen und den Lizenzbedingungen. Eingetragen in England und Wales, 1 St Martin's Le Grand, London EC1A 4EU, United Kingdom, Großbritannien. Die Investition in Finanzinstrumente bietet die Möglichkeit, hohe Gewinne zu erzielen, birgt aber auch gewisse Risiken. Es ist wichtig, jedes Instrument sorgfältig zu prüfen, bevor Sie mit der Investition beginnen. Es besteht die Möglichkeit, dass Sie Ihre Investition verlieren. Daher ist es ratsam, Risiken zu vermeiden, die Sie nicht eingehen möchten. Es wird dringend empfohlen, die Merkmale jedes Finanzinstruments zu studieren, bevor Sie mit dem Handel beginnen. Die Inhalte dieser Website stellen keine Beratung dar. Denken Sie daran, dass die Leistung in der Vergangenheit keine Garantie für den zukünftigen Erfolg ist. Wir empfehlen Ihnen, Ihr Anlageportfolio zu streuen, um Risiken zu minimieren.",
      },
    },
  },
});
</script>

<template>
  <footer class="footer">
    <div data-aos="fade-up" class="container">
      <div class="footer__top">
        <div class="row row-gap-30">
          <div class="col-xl-3">
            <router-link
              :to="$localePath({ name: 'Home' })"
              class="footer__logo"
            >
              <img :src="require('@/assets/img/logo.svg')" alt="" />
            </router-link>
          </div>
          <div class="col-xl-6">
            <div class="footer__menu">
              <div class="footer-menu__item hide__menu-item">
                <router-link
                  :to="$localePath({ name: 'Trade' })"
                  class="footer-menu__link"
                >
                  {{ $t("menu.trade") }}
                </router-link>
                <div class="footer__sub-menu">
                  <router-link
                    :to="$localePath({ name: 'Currency' })"
                    class="footer-sub-menu__link"
                  >
                    {{ $t("subMenu.crypto") }}
                  </router-link>
                  <router-link
                    :to="$localePath({ name: 'Security' })"
                    class="footer-sub-menu__link"
                  >
                    {{ $t("subMenu.security") }}
                  </router-link>
                </div>
              </div>
              <div class="footer-menu__item">
                <router-link
                  :to="$localePath({ name: 'Markets' })"
                  class="footer-menu__link"
                >
                  {{ $t("menu.markets") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'About' })"
                  class="footer-menu__link"
                >
                  {{ $t("menu.about") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Trade' })"
                  class="footer-menu__link link_hide"
                >
                  {{ $t("menu.trade") }}
                </router-link>
              </div>
              <div class="footer-menu__item">
                <router-link
                  :to="$localePath({ name: 'Platform' })"
                  class="footer-menu__link"
                >
                  {{ $t("menu.platform") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Contacts' })"
                  class="footer-menu__link"
                >
                  {{ $t("menu.contacts") }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="footer-menu__item support-block">
              <div class="footer__title">{{ $t("support") }}</div>
              <div class="footer__sub-menu">
                <p class="">
                  <span class="ic-mail text-link"></span>
                  <a href="mailto:support@optimaxfinance.com" class="text-link">
                    Email
                  </a>
                </p>
                <p class="">
                  <span class="ic-telegram text-link"></span>
                  <a
                    href="tg://resolve?domain=optimax_sup_bot"
                    class="text-link"
                  >
                    Telegram
                  </a>
                </p>

                <router-link
                  :to="$localePath({ name: 'Documents' })"
                  class="text-link underline"
                >
                  {{ $t("client") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <p translate="no">
          ©
          <span>{{ new Date().getFullYear() }}</span>
          OptimaX Finance All rights reserved. |
          <span translate="no">{{ $t("footerText") }}</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  background: $dark2;
  padding: 80px 0;
}
.footer__top {
}
.footer__bottom {
  margin-top: 60px;
  border-top: 1px solid rgba($white, 0.1);
  padding: 40px 0;
  font-size: 12px;
}
.footer__menu {
  display: flex;
  justify-content: space-between;
}
.footer-menu__item {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer-menu__link {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: $white;
  transition: $transition;

  &:hover {
    color: $primary-color;
  }
}
.footer__sub-menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 14px;
}
.footer-sub-menu__link {
  font-size: 14px;
  color: $body-color;
  font-weight: 400;
}
.footer__title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.support-block {
  text-align: right;
}
.link_hide {
  display: none;
}

@include media-breakpoint-down(xl) {
  .footer {
    padding: 60px 0;
  }
  .support-block {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;

    .footer__sub-menu {
      flex-direction: row;
      gap: 20px;
    }
  }
}
@include media-breakpoint-down(md) {
  .footer {
    padding: 45px 0 20px;
  }
  .footer__bottom {
    margin-top: 30px;
    padding: 20px 0;
  }
  .footer__sub-menu {
    gap: 15px;
  }
  .support-block {
    flex-direction: column;
    gap: 20px;
  }
}
@include media-breakpoint-down(sm) {
  .footer__menu {
    flex-wrap: wrap;
    gap: 20px;

    .footer-menu__item {
      gap: 20px;
      width: calc(50% - 10px);

      &.hide__menu-item {
        display: none;
      }
    }

    .link_hide {
      display: block;
    }
  }
}
</style>
